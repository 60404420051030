// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* DEV     https://leasing-ext-dev.apps.ambientesbc.com/suim/suimbd
 * QA      https://leasing-ext-qa.apps.ambientesbc.com/suim/suimbd
 * LOCAL   http://localhost:8081
 */

const urlServer = 'https://leasing-ext-qa.apps.ambientesbc.com/suim/suimbd';
//const urlServer = 'http://localhost:8080';

export const environment = {
  production: false,
  //url_API: 'https://iom-alb-dev.apps.ambientesbc.co',
  url_API: 'http://localhost:8080/v2/',
  postLogoutRedirectUri: 'https://suim-dev.apps.ambientesbc.com',
  clientId: 'c9d15ed0-c2bb-43fe-8639-f64bb874baa6',
  authority:
    'https://login.microsoftonline.com/428f4e2e-13bf-4884-b364-02ef9af41a1d',

  DOMAIN_OPERATIONS: urlServer,
  DOMAIN_MENEGEMENT: urlServer,
  DOMAIN_DATABASE: urlServer,
  DOMAIN_FILE: urlServer,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
