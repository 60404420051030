import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/data/services/auth/auth.service';
import { Profiles } from 'src/app/core/mocks/profiles.enum';
import { DataService } from 'src/app/data/services/data/data.service';
import { fadeAnimation } from './animations';
import { ProfileFacadeService } from './profile-store/profile.facade.service';
import { fadeTransition } from './routerAnimation';
import { CotizationsFacadeService } from './quotation/commercial/components/cotizations-store/cotizations.facade.service';
import { ImportationsFacadeService } from './general/components/import-store/importations.facade.service';
import { AppRoutes } from '@constants/router-path.constants';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeAnimation, fadeTransition()],
})
export class MasterComponent {
  canActivateGenerales: boolean = false;
  canActivateTemporales: boolean = false;
  canActivateCotizaciones: boolean = false;
  canActivateInicio: boolean = false;
  today = new Date();
  date = this.today.toLocaleDateString('es-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  hour = this.today.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  title: string = 'Inicio';
  profile: Profiles | undefined;
  urlCotization = '/app/cotizaciones';
  constructor(
    private data: DataService,
    private profileFacadeService: ProfileFacadeService,
    private cotizacionFacadeService: CotizationsFacadeService,
    private importationsFacadeService: ImportationsFacadeService,
    private authService: AuthService
  ) {
    this.profileFacadeService?.getCurretProfile().subscribe((profile) => {
      this.profile = profile?.authorities[0];
      this.enableModules();
    });
  }

  enableModules() {
    switch (this.profile) {
      case Profiles.ADMINISTRATOR:
        this.isAdministrator();
        break;
      case Profiles.COMMERCIAL:
        this.isCommercial();
        break;
      case Profiles.RENTING:
      case Profiles.CLIENT:
        this.urlCotization = AppRoutes.consultaGeneralDeImportaciones.path;
        this.canActivateCotizaciones = true;
        this.canActivateInicio = true;
        break;
      case Profiles.LOCAL_TRANSPORTATION:
      case Profiles.INTERNATIONAL_TRANSPORT:
        this.canActivateCotizaciones = true;
        this.canActivateInicio = true;
        break;
      case Profiles.CUSTOMS:
        this.canActivateCotizaciones = true;
        this.canActivateInicio = true;
        this.canActivateTemporales = true;
        break;
      case Profiles.COMMERCIAL_TRADE:
        this.canActivateInicio = true;
        break;
      default:
        this.canActivateInicio = true;
        break;
    }
  }

  isCommercial() {
    this.urlCotization = '/app/cotizaciones/comercial/cliente';
    this.canActivateCotizaciones = true;
    this.canActivateInicio = true;
  }
  isAdministrator() {
    this.canActivateGenerales = true;
    this.canActivateCotizaciones = true;
    this.canActivateInicio = true;
    this.canActivateTemporales = true;
    this.urlCotization = '/app/cotizaciones/administrador/vinculacion-solicitud-cotizacion';
  }

  changeTitle(title: string) {
    this.cotizacionFacadeService.resetCotizationDetailFlagsTrue();
    this.cotizacionFacadeService.resetFlagsTrue();
    this.importationsFacadeService.resetImportationsFlagsTrue();
    this.title = '';
    this.title = title;
    this.data.setTitleMenu(title);
  }

  logOut() {
    this.authService.logOut();
  }
}
