import { Injectable } from '@angular/core';
import { APIS_URL } from 'src/app/core/constants/url';
import { ApiHelperService } from 'src/app/data/services/api-helper/api-helper.service';
import { IImportationData } from 'src/app/data/interfaces/IImportationData.interface';
import { Observable } from 'rxjs';
import { IImportation, IImportationsPagGenerales } from 'src/app/data/interfaces/IImportation.interface';
import { IImportationDataAlly } from 'src/app/data/interfaces/IImportationDataAlly.interface';
import { map } from 'rxjs/operators';
import {
  CustomsAssetseDetails,
  ICustomsInquiry,
  ImpcustomsAssetses,
  MessageFromAssetsesCustoms,
} from 'src/app/data/interfaces/ICustomsInquiry.interface';
import { ICustomsDataUpdate } from 'src/app/data/interfaces/ICustomsDataUpdate.interface';
import {
  ICustomnsNationalization,
  PageAduanaDeclaraciones,
} from 'src/app/data/interfaces/ICustomsNationalization.interface';
import { ICustomsNatDataUpdate } from 'src/app/data/interfaces/ICustomsNatDataUpdate.interface';
import {
  IImpLocalTransport,
  MessageFromDeleteContainer,
  PageLocalTransportContainers,
  PageLocalTransportContainersDetail,
} from 'src/app/data/interfaces/IImpLocalTransport.interface';
import { IImpLocalTransportUpdate } from 'src/app/data/interfaces/IImpLocalTransportUpdate.interface';
import { IImportationFilter } from 'src/app/data/interfaces/IImportationFilter.interface';
import { IImportationFilterByDate } from 'src/app/data/interfaces/IImportationFilterByDate.interfaces';
import { IDispatchData } from 'src/app/data/interfaces/IDispatchData.interface';
import { IDispatch } from 'src/app/data/interfaces/IDispatch.interface';
import {
  IImpIntTransport,
  PageIntTransportCargaSuelta,
  PageIntTransportCargaSueltaDetail,
  PageIntTransportContainers,
  PageIntTransportContainersDetail,
} from 'src/app/data/interfaces/IImpIntTransport.interface';
import { IImpIntTransportUpdate } from 'src/app/data/interfaces/IImpIntTransportUpdate.interface';
import { ImpProcedures } from 'src/app/data/interfaces/IImpProcedures.interface';
import { ImpProceduresDetails } from '../../../interfaces/IImpProcedures.interface';
import { IImportationsPag } from '../../../interfaces/IImportationsPaginator.interface';
import { IImportPreData } from '../../../interfaces/IImportationDataPreUp.interface';

@Injectable({
  providedIn: 'root',
})
export class ImportationService {

  private endPointImportations = APIS_URL.importations;
  private endPointCustoms = APIS_URL.lCoordinationCustoms;
  private endPointCustomsNat = APIS_URL.lCoordinationCustomsNat;
  private endPointImpLocalTransp = APIS_URL.ImpLocalTransp;
  private endPointImpIntTransp = APIS_URL.ImpIntTransp;
  private endpointLocalTransportContainer = APIS_URL.localTransportContainers;
  private endPointCustomsAssetses = APIS_URL.lCoordinationCustomsAssetses;
  private endpointIntTransportContainer = APIS_URL.internationalTransportContainer;
  private ennpointIntTransportCargaSuelta = APIS_URL.internationalTransportCargaSuelta;
  private endpointsaveDeclaraciones = APIS_URL.saveFilesDeclarations;
  private endPointProcedures = APIS_URL.impProcedures;
  private endpointsaveDeclaracionesFiles = APIS_URL.saveFilesDeclarationsfiles;

  constructor(private apiHelperService: ApiHelperService) {
    // This is intentional
  }

  saveImportationData(importationData: IImportationData): Observable<IImportation> {
    return this.apiHelperService.post<IImportation>(this.endPointImportations, importationData);
  }

  createImportationData(importationDataAlly: IImportationDataAlly): Observable<IImportation> {
    return this.apiHelperService.put<IImportation>(this.endPointImportations, importationDataAlly);
  }

  editImportationData(importNumber: string, importationData: IImportPreData): Observable<IImportation> {
    const params = `/edit?importNumber=${importNumber}`;
    return this.apiHelperService.put(this.endPointImportations + params, importationData).pipe(map((data: any) => data));
  }

  searchImportationByFilter(body?: IImportationFilter, page?: number, size?: number, isImport?: string): Observable<IImportationsPagGenerales> {
    return this.apiHelperService.post<IImportationsPagGenerales>(this.endPointImportations + `/filter?page=${page}&size=${size}&isImport=${isImport}`, body)
      .pipe(map((data: any) => data));
  }

  searchImportationByFilterToDelete(body?: IImportationFilter, page?: number, size?: number): Observable<IImportationsPagGenerales> {
    return this.apiHelperService.post<IImportationsPagGenerales>(this.endPointImportations + `/filter-delete?page=${page}&size=${size}`, body)
      .pipe(map((data: any) => data));
  }

  createDispatch(dispatchData: IDispatchData, dispatchNumber: string): Observable<IDispatch> {
    const url = `${this.endPointImportations}/dispatch?iNum=${dispatchNumber}`
    return this.apiHelperService.post<IDispatch>(url, dispatchData);
  }

  saveImportationTransporteLocalContenedores(idTransport: number, requestBody: PageLocalTransportContainersDetail): Observable<PageLocalTransportContainersDetail> {
    const params = `${this.endpointLocalTransportContainer}?impTransLocalId=${idTransport}`;
    return this.apiHelperService.post(params, requestBody).pipe(map((data: any) => data.TypeOfLoad));
  }

  saveContainerDataInt(id: number, requestBody: PageIntTransportContainersDetail): Observable<PageIntTransportContainersDetail> {
    const params = `${this.endpointIntTransportContainer}?impTransIntId=${id}`;
    return this.apiHelperService.post(params, requestBody).pipe(map((data: any) => data.Container));
  }

  getDeclarationsDataPaginados(importNumber: string, pageSize: number, pageindex: number): Observable<PageAduanaDeclaraciones> {
    const params = `/filter?importNumber=${importNumber}&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService.post(this.endpointsaveDeclaraciones + params).pipe(map((data: any) => data));
  }

  saveFileDeclraraciones(id: string, requestBody: File): Observable<any> {
    const params = `?importNumber=${id}`;
    const fromData = new FormData();
    fromData.append('file', requestBody, requestBody.name);
    return this.apiHelperService.post(this.endpointsaveDeclaracionesFiles + params, fromData).pipe(map((data: any) => data));
  }

  saveCargaSueltaDataInt(id: number, requestBody: PageIntTransportCargaSueltaDetail): Observable<PageIntTransportCargaSueltaDetail> {
    const params = `?impTransIntId=${id}`;
    return this.apiHelperService.post(this.ennpointIntTransportCargaSuelta + params, requestBody).pipe(map((data: any) => data.Load));
  }

  updateImportationTransporteLocalContenedores(idContainer: number, requestBody: PageLocalTransportContainersDetail): Observable<PageLocalTransportContainersDetail> {
    const params = `?id=${idContainer}`;
    return this.apiHelperService.put(this.endpointLocalTransportContainer + params, requestBody).pipe(map((data: any) => data.TypeOfLoad));
  }

  updateImportationTransporteInternationalCarga(idContainer: number, requestBody: PageIntTransportCargaSueltaDetail): Observable<PageIntTransportCargaSueltaDetail> {
    const params = `?id=${idContainer}`;
    return this.apiHelperService.put(this.ennpointIntTransportCargaSuelta + params, requestBody).pipe(map((data: any) => data.Load));
  }

  updateImportationTransporteInternationalContenedores(idContainer: number, requestBody: PageIntTransportContainersDetail): Observable<PageIntTransportContainersDetail> {
    const params = `?id=${idContainer}`;
    return this.apiHelperService.put(this.endpointIntTransportContainer + params, requestBody).pipe(map((data: any) => data.Container));
  }

  deleteImportationContainer(
    containerId: number
  ): Observable<MessageFromDeleteContainer> {
    const params = `/by-id/${containerId}`;
    return this.apiHelperService.delete(
      this.endpointLocalTransportContainer + params
    ).pipe(map((data: any) => data));
  }

  deleteImportations(
    body: any
  ): Observable<any> {
    return this.apiHelperService.delete(
      this.endPointImportations, "", { body }
    ).pipe(map((data: any) => data));
  }

  deleteImportationCargaSueltaInternational(
    containerId: number
  ): Observable<MessageFromDeleteContainer> {
    const params = `/by-id/${containerId}`;
    return this.apiHelperService.delete(
      this.ennpointIntTransportCargaSuelta + params
    ).pipe(map((data: any) => data));
  }

  deleteImportationContainerInternational(
    containerId: number
  ): Observable<MessageFromDeleteContainer> {
    const params = `/by-id/${containerId}`;
    return this.apiHelperService.delete(
      this.endpointIntTransportContainer + params
    ).pipe(map((data: any) => data));
  }

  getImportatioInternationalCargaSueltaP(
    importNumber: string,
    pageSize: number,
    pageindex: number
  ): Observable<PageIntTransportCargaSuelta> {
    const params = `/filter?importNumber=${importNumber}&&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService.post(
      this.ennpointIntTransportCargaSuelta + params,
      {}
    ).pipe(map((data: any) => data));
  }

  getImportationInternationalContainersP(
    importNumber: string,
    pageSize: number,
    pageindex: number
  ): Observable<PageIntTransportContainers> {
    const params = `/filter?importNumber=${importNumber}&&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService.post(
      this.endpointIntTransportContainer + params,
      {}
    ).pipe(map((data: any) => data));
  }

  getImportationTransporteLocalCargaSuelta(
    typeLoad: string,
    importNumber: string,
    pageSize: number,
    pageindex: number
  ): Observable<PageLocalTransportContainers> {
    return this.getImportationTransporteLocalContenedores(typeLoad, importNumber, pageSize, pageindex);
  }

  getImportationTransporteLocalContenedores(
    typeLoad: string,
    importNumber: string,
    pageSize: number,
    pageindex: number
  ): Observable<PageLocalTransportContainers> {
    const params = `/filter?typeLoad=${typeLoad}&&importNumber=${importNumber}&&page=${pageindex}&size=${pageSize}`;
    return this.apiHelperService.get(
      this.endpointLocalTransportContainer + params
    ).pipe(map((data: any) => data));
  }

  public findImportationByParams(
    params: IImportationFilterByDate,
    page: number,
    size: number
  ): Observable<IImportationsPag> {
    const filter = `filter-by-date?page=${page}&size=${size}`;
    return this.apiHelperService.post<IImportationsPag>(
      `${this.endPointImportations}/${filter}`,
      params
    ).pipe(map((data: any) => data));
  }

  getImportationByImportNumber(importNumber: string): Observable<IImportation> {
    const params = `/by-import-number?iNum=${importNumber}`;
    return this.apiHelperService.get(this.endPointImportations + params).pipe(
      map((data: any) => data.Import)
    );
  }

  /** SECCION ADUANAS */
  getCustomsByImportNumber(importNumber: string): Observable<ICustomsInquiry> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointCustoms + params).pipe(
      map((data: any) => data.Customs)
    );
  }

  updateCustomsData(id: number, customsDataUpdate: ICustomsDataUpdate): Observable<ICustomsInquiry> {
    const queryParams = `?id=${id}`;
    return this.apiHelperService.put<ICustomsInquiry>(this.endPointCustoms + queryParams, customsDataUpdate);
  }

  /** SECCION ADUANAS ACTIVOS */
  postFilterImpAssetsCustoms(importNumber: string, page: number, size: number): Observable<ImpcustomsAssetses> {
    return this.apiHelperService.post<ImpcustomsAssetses>(this.endPointCustomsAssetses + `/filter?importNumber=${importNumber}&page=${page}&size=${size}`
    ).pipe(map((data: any) => data));
  }

  postImpAssetsCustoms(importNumber: string, body: ImpcustomsAssetses): Observable<ImpcustomsAssetses> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.post(this.endPointCustomsAssetses + params, body).pipe(map((data: any) => data));
  }

  putImpAssetsCustomsById(id: number, impAssetsCustomsDataUpdate: CustomsAssetseDetails): Observable<CustomsAssetseDetails> {
    const params = `?id=${id}`;
    return this.apiHelperService.put(this.endPointCustomsAssetses + params, impAssetsCustomsDataUpdate).pipe(map((data: any) => data));
  }

  deleteImpAssetsCustomsById(
    id: number
  ): Observable<MessageFromAssetsesCustoms> {
    const params = `?id=${id}`;
    return this.apiHelperService.delete(
      this.endPointCustomsAssetses + params
    ).pipe(map((data: any) => data));
  }

  saveImpAssetsCustomsData(
    importNumber: string,
    impAssetsCustomsData: CustomsAssetseDetails
  ): Observable<CustomsAssetseDetails> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.post(
      this.endPointCustomsAssetses + params,
      impAssetsCustomsData
    ).pipe(map((data: any) => data));
  }

  /*************** ADUANAS NACIONALIZACION *********************/
  getCustomsNaturalizationByImportNumber(
    importNumber: string
  ): Observable<ICustomnsNationalization> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointCustomsNat + params).pipe(
      map((data: any) => data.CustomsNationalization)
    );
  }

  updateCustomsNatData(
    id: number,
    customsNatDataUpdate: ICustomsNatDataUpdate
  ): Observable<ICustomnsNationalization> {
    const queryParams = `?id=${id}`;
    return this.apiHelperService.put<ICustomnsNationalization>(
      this.endPointCustomsNat + queryParams,
      customsNatDataUpdate
    );
  }

  /** SECCION IMPORTACION -> TRANSPORTE LOCAL */
  getLocalTransportByImportNumber(
    importNumber: string
  ): Observable<IImpLocalTransport> {
    const params = `/by-importNumber?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointImpLocalTransp + params).pipe(
      map((data: any) => data.ImportLocalTransport)
    );
  }

  putImpLocalTransportById(
    id: number,
    impLocalTransportUpdate: IImpLocalTransportUpdate
  ): Observable<IImpLocalTransport> {
    const queryParams = `?localImportId=${id}`;
    return this.apiHelperService.put<IImpLocalTransport>(
      this.endPointImpLocalTransp + queryParams,
      impLocalTransportUpdate
    );
  }

  /** SECCION IMPORTACION -> TRANSPORTE INTERNACIONAL */
  getIntTransportByImportNumber(
    importNumber: string
  ): Observable<IImpIntTransport> {
    const params = `/by-importNumber?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointImpIntTransp + params).pipe(
      map((data: any) => data.ImportIntTransport)
    );
  }

  putImpIntTransportById(
    id: number,
    impIntTransportUpdate: IImpIntTransportUpdate
  ): Observable<IImpIntTransport> {
    const queryParams = `?intImportId=${id}`;
    return this.apiHelperService.put<IImpIntTransport>(
      this.endPointImpIntTransp + queryParams,
      impIntTransportUpdate
    );
  }

  /** SECCION IMPORTACION -> TRAMITES */

  getImpProceduresListFilter(importNumber: string): Observable<ImpProcedures> {
    const params = `/filter?importNumber=${importNumber}`;
    return this.apiHelperService.get(this.endPointProcedures + params).pipe(
      map((data: any) => data.FormalitiesDocuments)
    );
  }

  postImpProceduresData(importNumber: string, body: any): Observable<any> {
    const params = `?importNumber=${importNumber}`;
    return this.apiHelperService.post(
      this.endPointProcedures + params,
      body
    ).pipe(map((data: any) => data));
  }

  deleteImpProceduresData(id: number): Observable<string> {
    const params = `/by-id/${id}`;
    return this.apiHelperService.delete(this.endPointProcedures + params).pipe(
      map((data: any) => data.Message)
    );
  }

  putImpProceduresDataById(
    id: number,
    impProceduresDataUpdate: ImpProceduresDetails
  ): Observable<any> {
    const queryParams = `?formalitiesDocid=${id}`;
    return this.apiHelperService.put<any>(
      this.endPointProcedures + queryParams,
      impProceduresDataUpdate
    );
  }

  postFileProceduresData(importNumber: string, body: any): Observable<any> {
    const params = `/addDocuments?importNumber=${importNumber}`;
    return this.apiHelperService.post(
      this.endPointProcedures + params,
      body
    ).pipe(map((data: any) => data));
  }

  deleteFileProceduresData(id: number, updateAt: string): Observable<string> {
    const params = `/deleteFile/by-id?id=${id}&updateAt=${updateAt}`;
    return this.apiHelperService.delete(this.endPointProcedures + params).pipe(
      map((data: any) => data.Message)
    );
  }


}
